const VisaCard = ({ ...props }) => {
  return (
    <svg
      width={40}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38 0H2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4 6.207 14.555 17.8h2.952l1.845-11.593H16.4Zm-4.328 0-2.815 7.974-.333-1.717-.073-.384a9.592 9.592 0 0 0-2.619-3.395c-.44-.36-.884-.666-1.313-.929L7.477 17.8h3.075L15.25 6.207h-3.177Zm11.52 3.219c0-1.31 2.874-1.142 4.138-.43l.42-2.49S26.85 6 25.497 6c-1.465 0-4.944.655-4.944 3.838 0 2.995 4.083 3.032 4.083 4.605 0 1.573-3.662 1.292-4.87.3l-.44 2.602s1.319.655 3.333.655c2.014 0 5.053-1.067 5.053-3.969 0-3.014-4.12-3.295-4.12-4.605Zm12.045-3.219h-2.373c-1.096 0-1.363.864-1.363.864l-4.402 10.73h3.077l.615-1.722h3.753l.346 1.721H38L35.637 6.207ZM32.042 13.7l1.55-4.339.873 4.339h-2.423Z"
        fill="#005BAC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.858 7.001S7.743 6 6.515 6H2.052L2 6.188s2.145.455 4.203 2.157C8.169 9.972 8.81 12 8.81 12l-.953-4.999Z"
        fill="#F6AC1D"
      />
    </svg>
  )
}

export default VisaCard
