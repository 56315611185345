const MasterCard = ({ ...props }) => {
  return (
    <svg
      width={40}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38 0H2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"
        fill="#fff"
      />
      <path d="M23.245 5.604h-6.49v11.792h6.49V5.604Z" fill="#FF5F00" />
      <path
        d="M17.167 11.5A7.518 7.518 0 0 1 20 5.604a7.358 7.358 0 0 0-10.032.808 7.562 7.562 0 0 0 0 10.176A7.358 7.358 0 0 0 20 17.396a7.518 7.518 0 0 1-2.833-5.896Z"
        fill="#EB001B"
      />
      <path
        d="M32 11.5c0 2.871-1.621 5.49-4.175 6.746a7.35 7.35 0 0 1-7.825-.85 7.532 7.532 0 0 0 2.833-5.896c0-2.3-1.044-4.474-2.833-5.896a7.35 7.35 0 0 1 7.825-.85C30.379 6.01 32 8.63 32 11.5Z"
        fill="#F79E1B"
      />
    </svg>
  )
}

export default MasterCard
