const fetcher = async (
  input: RequestInfo,
  init: RequestInit,
  ...args: any[]
) => {
  const res = await fetch(input, init)
  return res.json()
}

export const swrOptions = {
  revalidateOnFocus: false,
}

export default fetcher
