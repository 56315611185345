const Cart = ({ ...props }) => {
  const { fill } = props
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.09 12.9a1.79 1.79 0 0 0 1.575-.927l3.22-5.841a.897.897 0 0 0-.782-1.332H6.788L5.942 3H3v1.8h1.8l3.238 6.831-1.214 2.196c-.657 1.206.207 2.673 1.574 2.673h10.796v-1.8H8.398l.99-1.8h6.702ZM7.642 6.6h10.931l-2.483 4.5H9.774L7.642 6.6Zm.756 10.8c-.99 0-1.79.81-1.79 1.8s.8 1.8 1.79 1.8 1.8-.81 1.8-1.8-.81-1.8-1.8-1.8Zm8.997 0c-.99 0-1.79.81-1.79 1.8s.8 1.8 1.79 1.8 1.799-.81 1.799-1.8-.81-1.8-1.8-1.8Z"
        fill={fill || '#fff'}
      />
    </svg>
  )
}

export default Cart
